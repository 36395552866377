import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { User } from '@models/index';
import { DeliveryOptionEnum } from '@utils/enum/delivery-option';
import { de } from 'date-fns/locale';

@Component({
  selector: 'app-delivery-option',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './delivery-option.component.html',
  styleUrl: './delivery-option.component.css'
})
export class DeliveryOptionComponent {

  deliveryOptionEnum = DeliveryOptionEnum
  @Input() selectedDeliveryMode: number = this.deliveryOptionEnum.PICK_UP;
  @Input() enableDelivery: boolean | undefined = false;
  @Input() user: User | null = null;
  @Input() uniqueId: string = '';
  @Input() vendorInstructions!: string;
  @Input() deliveryInstructions!: string;

  @Output() selectedDeliveryModeEvent = new EventEmitter<number>();
  @Output() changeDeliveryAddressEvent = new EventEmitter<void>();
  @Output() toggleDeliveryInstructionsDialogEvent = new EventEmitter<void>();
  @Output() toggleVendorInstructionsDialogEvent = new EventEmitter<void>();

  deliveryInstructionsDialog: boolean = false;



  toggleDeliveryInstructionsDialog() {
    this.toggleDeliveryInstructionsDialogEvent.emit();
    

  }

  toggleVendorInstructionsDialog() {
    this.toggleVendorInstructionsDialogEvent.emit();
  }

  deliveryModeChanged(): void {
    this.selectedDeliveryModeEvent.emit(this.selectedDeliveryMode);
    console.log(this.selectedDeliveryMode)
  }

  changeDeliveryAddress() {
    this.changeDeliveryAddressEvent.emit();
  }
}
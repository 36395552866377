<div
  class="px-1 py-2 h-[175px] rounded-xl border border-gray-200 dark:border-gray-700 transition-all duration-300 hover:shadow-md">
  <div class="flex flex-row justify-between items-center h-full">
    <!-- Left Content -->
    <div class="flex flex-col px-2 max-w-[70%] 2xl:px-4">
      <div class="flex flex-col justify-start space-y-1">
        <span class="text-lg font-semibold truncate lg:text-lg 2xl:text-2xl text-vendblocblue dark:text-white">{{
          cardName }}</span>
        @if(product.productDescription){
        <p  (click)="showProductInfo()"
          class="text-sm font-light 2xl:text-base cursor-pointer text-vendblocblue dark:text-white">{{product.productDescription.length
          > 80 ? ((product.productDescription.substring(0, 80))) : product.productDescription}}
          @if(product.productDescription.length > 80){
            <span class="font-semibold">...see more</span>
          }
        </p>
        }

        <div class="flex flex-row gap-x-6 items-center py-1 2xl:py-2">
          <span class="font-normal text-md 2xl:text-xl text-vendblocgrey dark:text-white">Price</span>
          <span class="font-bold text-black dark:text-white text-md">{{ price }}</span>
        </div>
        <app-product-quantity [quantity]="product.orderQuantity"
          (incrementDecrementEvent)="handleIncrementDecrement($event)"
          (quantityChangedEvent)="handleDirectQuantityInput($event)"></app-product-quantity>
      </div>
    </div>

    <!-- Right Content -->
    <div class="relative z-50 flex flex-col h-full max-w-[30%] items-center justify-between">
      <div class="absolute top-2 right-2 cursor-pointer">
        <i class="pi pi-info-circle text-vendblocpurple dark:text-white hover:text-vendblocpurple/80"
          (click)="showProductInfo()"></i>
      </div>
      <div class="flex items-center justify-center h-[75%] w-full">
        <app-avatar-image [image]="product.productImage"></app-avatar-image>
      </div>
      <button (click)="addToCart(product.productId!)"
        class="px-1 py-2 text-sm text-white rounded-md lg:px-2 2xl:font-semibold lg:text-md bg-vendblocpurple">
        Add to Cart
      </button>
    </div>
  </div>
</div>

<!-- Product Info Dialog -->
<app-dialog [(visible)]="showInfoDialog" (hide)="showInfoDialog = false" (cancel)="showInfoDialog = false"
  header="{{product.product}} Details" width="40%" [showFooter]="false">
  <div class="flex flex-col space-y-4">
    <div class="flex w-full justify-center">
      <img [src]="product.productImage" [alt]="product.product" class="w-32 h-32 rounded-lg">
    </div>
    <!-- grid grid-cols-2 -->
    <div class="flex flex-row justify-between gap-4">
      <div>
        <h3 class="text-sm font-semibold">Name</h3>
        <p class="text-vendblocprimayGrey2">{{product.product}}</p>
      </div>
      <div>
        <h3 class=" text-sm font-semibold">Category</h3>
        <p class="text-vendblocprimayGrey2">{{product.productCategory}}</p>
      </div>
      <div>
        <h3 class="text-sm font-semibold">Price</h3>
        <p class="text-vendblocprimayGrey2">{{price}}</p>
      </div>
     
    </div>
    <div class="w-full">
      <h3 class="text-sm font-semibold">Description</h3>
      <p class="text-vendblocprimayGrey2">{{product.productDescription}}</p>
    </div>
  </div>
</app-dialog>
<div class="p-2 space-y-1 rounded-lg md:p-4 bg-vendblocpurple/5 md:space-y-2 2xl:space-y-4">

  <div>
    <!-- "Delivery Mode: "{{selectedDeliveryMode}} <br> -->
    @if(enableDelivery){
    <h4 class="mb-1 text-primary font-semibold md:mb-2">Choose Delivery Option</h4>
    }
  </div>
  <div class="flex justify-between">
    <div class="flex items-center space-x-1">
      <input type="radio" [(ngModel)]="selectedDeliveryMode" (change)="deliveryModeChanged()" [value]=0
        class="custom-radio" [attr.name]="'delivery_'+uniqueId" id="pickup" />
      <label [class.text-primary]="selectedDeliveryMode === deliveryOptionEnum.PICK_UP" [class.text-gray-500]="deliveryOptionEnum.DELIVERY"
        for="pickup">Pick Up</label>
    </div>
    @if(enableDelivery){
    <div class="flex items-center space-x-1">
      <input type="radio" [(ngModel)]="selectedDeliveryMode" (change)="deliveryModeChanged()" [value]=1
        class="custom-radio" [attr.name]="'delivery_'+uniqueId" id="deliver" />
      <label [class.text-primary]="selectedDeliveryMode === deliveryOptionEnum.DELIVERY" [class.text-gray-500]="selectedDeliveryMode === deliveryOptionEnum.PICK_UP"
        for="deliver">Delivery</label>
    </div>}
  </div>

  <div class="flex-col mt-2 space-y-2">
    @if(user?.address !== null && selectedDeliveryMode === deliveryOptionEnum.DELIVERY ){
    <div class="flex justify-between">
      <span class="font-normal">{{user?.address}}</span>
      <span class="font-semibold cursor-pointer text-vendblocpurple" (click)="changeDeliveryAddress()">Change</span>
    </div>
    }
    @if(selectedDeliveryMode === deliveryOptionEnum.DELIVERY){
    <div class="flex flex-col gap-2">
      <div class="flex justify-between">
        <h4 class="font-normal text-primary">Delivery Instruction</h4>
        <span class="font-semibold cursor-pointer text-secondary" (click)="toggleDeliveryInstructionsDialog()">
          {{deliveryInstructions !== "" ? "Update Note": "Add Note"}}
        </span>
      </div>
      @if(deliveryInstructions !== ""){
      <div (click)="toggleDeliveryInstructionsDialog()"
        class="bg-white hover:border hover:border-purple-200 cursor-pointer p-2">
        {{deliveryInstructions.length > 30 ? ((deliveryInstructions.substring(0, 30))) : deliveryInstructions}}
        @if(deliveryInstructions.length > 30){
        <span class="font-semibold">...see more</span>

        }
      </div>
      }
    </div>

    }
    <div class="flex flex-col gap-2 mt-4">
      <div class="flex justify-between font-semibold">
        <h4 class="font-normal text-primary">Vendor Instruction</h4>

        <span class="font-semibold cursor-pointer text-secondary" (click)="toggleVendorInstructionsDialog()">
          {{vendorInstructions !== "" ? "Update Note": "Add Note"}}
        </span>

      </div>
      @if(vendorInstructions !== ""){
      <div (click)="toggleVendorInstructionsDialog()"
        class="bg-white hover:border hover:border-purple-200  cursor-pointer p-2">
        {{vendorInstructions.length > 30 ? ((vendorInstructions.substring(0, 30))) :
        vendorInstructions}}
        @if(vendorInstructions.length > 30){
        <span class="font-semibold">...see more</span>
        }
      </div>
      }
    </div>

  </div>
</div>
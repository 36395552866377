import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Inventory } from '@models/index';
import { FormsModule, ReactiveFormsModule, } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ProductQuantityComponent } from "../product-quantity/product-quantity.component";
import { AvatarImageComponent } from '../index';
import { formatCurrency } from '@utils/shared/shared';
import { AppDialogComponent } from '../app-dialog/app-dialog.component';

@Component({
  selector: 'app-order-product-card',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CommonModule,  ProductQuantityComponent, AvatarImageComponent, AppDialogComponent],
  templateUrl: './order-product-card.component.html',
  styleUrl: './order-product-card.component.css'
})
export class OrderProductCardComponent {

  showInfoDialog: boolean = false;

  @Input() cardName: string = '';
  @Input() iconBackgroundColor: string = '';
  @Input() cardCount: string = '0';
  @Input() icon: string = '';
  @Input() product! : Inventory;
  @Output() addToCartEvent = new EventEmitter<number>();
  @Output() incrementDecrementQuantity = new EventEmitter<{cartItem: Inventory, increment: boolean}>();
  @Output() directQuantityChange = new EventEmitter<{productId: number, quantity: number}>();


  get price(): string {
    if (this.product.orderQuantity < 1) {
      return formatCurrency(this.product.salesPrice, 'NGN', 0);
    }
    return formatCurrency(this.product.salesPrice * this.product.orderQuantity, 'NGN', 0);
  }

  handleIncrementDecrement(increment: boolean) {
    this.incrementDecrementQuantity.emit({cartItem: this.product, increment: increment});
  }

  handleDirectQuantityInput(quantity: number) {
    const productId = this.product.productId;
    this.directQuantityChange.emit({productId, quantity});
  }

  addToCart(id:number){
     this.addToCartEvent.emit(id);
  }

  showProductInfo() {
    this.showInfoDialog = true;
  }

  formatCurrency = formatCurrency;

}
